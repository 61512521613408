import { graphql, useStaticQuery } from "gatsby";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { Education, Institution, Link } from "./primitives";

export interface HeadlineLinks {
  institution: Institution;
}

export interface Biography {
  profile: {
    name: string;
    links: Link[];
    institutions: Institution[];
  };
  education: Education[];
  photo: FileNode;
  headline: string;
  interests: Link[];
  biography: string;
}

export default function getBiography(): Biography {
  const { mdx } = useStaticQuery(graphql`
    query GetBiography {
      mdx(fields: { page: { eq: "biography" } }) {
        frontmatter {
          profile: author {
            name
            links {
              icon
              name
              url
            }
            institutions {
              name
              url
            }
          }
          education {
            completion
            course
            degree
            institution {
              name
              url
            }
          }
          headline
          interests {
            icon
            name
          }
          photo: featured_image {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 80)
            }
          }
        }
        biography: body
      }
    }
  `);
  return { biography: mdx.biography, photo: mdx.photo, ...mdx.frontmatter };
}
