import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import getBiography from "../../core/interfaces/biography";
import SocialIcons from "../components/social-icons";

export default function ProfileBlock(props: {
  color: string;
  colorDark: string;
}): React.ReactElement {
  const { color, colorDark } = props;
  const bio = getBiography();
  const avatar = getImage(bio.photo);

  return (
    <section className={`pt-16 pb-40 items-start flex bg-${color} dark:bg-${colorDark}`}>
      <div className="container mx-auto pb-20 h-full w-full">
        <div className="flex flex-wrap items-start mt-24 h-full w-full">
          <div className="relative w-300-px lg:w-4/12 mx-auto">
            <div className="w-300-px h-300-px mx-auto">
              <div className="absolute z-10 opacity-0 hover:opacity-100 transition">
                <GatsbyImage
                  image={avatar}
                  alt={bio.profile.name}
                  className="rounded-br-10xl rounded-tl-10xl rounded-bl-xl rounded-tr-xl"
                />
              </div>

              <div className="absolute z-0">
                <img src="davi.svg" className="invert dark:invert-0" width="300" height="300" />
              </div>
            </div>

            <h2
              id="profile"
              className="text-center font-semibold text-4xl text-gray-700 dark:text-white mt-6"
            >
              {bio.profile.name}
            </h2>

            <h2
              className={`text-center font-semibold text-2xl text-gray-600 dark:text-gray-100 mt-2`}
            >
              {bio.headline}
            </h2>

            <div className="mt-4 text-center">
              {bio.profile.institutions.map((institution, hlink_ix, { length }) => (
                <span
                  key={hlink_ix}
                  className="text-xl font-bold leading-normal mt-0 mb-2 text-gray-500 dark:text-gray-200"
                >
                  <a
                    href={institution.url}
                    target="_blank"
                    className="hover:text-gray-400 transition-all"
                  >
                    {institution.name}
                  </a>
                  {hlink_ix != length - 1 && <br />}
                </span>
              ))}
            </div>

            <SocialIcons className="mt-4 mb-12 text-center" />
          </div>

          <div className="w-full lg:w-8/12 px-4 md:pl-10 md:pr-0">
            <h2 className="text-left font-semibold text-4xl text-gray-700 dark:text-gray-200">
              About me
            </h2>
            <hr />

            <div className="mt-8 text-gray-600 dark:text-gray-300 text-xl prose-lg">
              <MDXRenderer>{bio.biography}</MDXRenderer>
            </div>

            <div className="flex flex-wrap items-start h-full mt-24">
              <div className="w-full md:w-6/12">
                <h2 className="text-left font-semibold text-4xl text-gray-700 dark:text-gray-200">
                  Interests
                </h2>
                <ul className="list-none mt-6">
                  {bio.interests.map((interest, interest_ix) => (
                    <li className="py-2" key={interest_ix}>
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                            <i className={interest.icon}></i>
                          </span>
                        </div>
                        <div>
                          <p className="text-gray-600 dark:text-gray-300 text-xl">
                            {interest.name}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="w-full md:w-6/12">
                <h2 className="text-left font-semibold text-4xl text-gray-700 dark:text-gray-200 mt-12 md:mt-0">
                  Education
                </h2>
                <ul className="list-none mt-6">
                  {bio.education.map((education, edu_ix) => (
                    <li className="py-2" key={edu_ix}>
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                            <i className="fas fa-graduation-cap"></i>
                          </span>
                        </div>
                        <div>
                          <p className="text-gray-600 dark:text-gray-300 text-xl">
                            {education.degree} in {education.course}
                          </p>
                          <p className="text-gray-500 dark:text-gray-400 text-large">
                            {education.institution.name},{" "}
                            {new Date(education.completion).getFullYear()}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
